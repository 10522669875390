import {
  HttpRequest,
  HttpHandlerFn,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

export function tokenInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const platformId = 'browser';
  const cookieService = new CookieService(document, platformId);
  const token = cookieService.get('token');
  const router = new Router();

  if (token) {
    const cloned = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    return next(cloned).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 401 &&
          error.error.message === 'Unauthenticated.'
        ) {
          router.navigate(['/login']);
        }
        return throwError(() => error);
      })
    );
  } else {
    return next(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 401 &&
          error.error.message === 'Unauthenticated.'
        ) {
          router.navigate(['/login']);
        }
        return throwError(() => error);
      })
    );
  }
}
