import {
  trigger,
  transition,
  style,
  query,
  animate,
} from '@angular/animations';

export const routeAnimations = trigger('routeAnimations', [
  transition('slideUpDownAnimation => noAnimation', [
    style({ position: 'relative' }),
    query(':enter', []),
  ]),
  transition('slideUpDownAnimation => slideLeftRightAnimation', [
    style({ position: 'relative' }),
    query(':enter', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        transform: 'translateX(100%)',
      }),
      animate('300ms ease-out', style({ transform: 'translateX(0)' })),
    ]),
  ]),
  transition('slideLeftRightAnimation => slideUpDownAnimation', [
    style({ position: 'relative' }),
    query(':leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        transform: 'translateX(0)',
        zIndex: 2000,
      }),
      animate('300ms ease-out', style({ transform: 'translateX(100%)' })),
    ]),
  ]),
  transition('* => slideUpDownAnimation', [
    style({ position: 'relative' }),
    query(':enter', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        transform: 'translateY(100%)',
      }),
      animate('300ms ease-out', style({ transform: 'translateY(0)' })),
    ]),
  ]),
  transition('slideUpDownAnimation => *', [
    style({ position: 'relative' }),
    query(':leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        transform: 'translateY(0)',
        zIndex: 2000,
      }),
      animate('300ms ease-out', style({ transform: 'translateY(100%)' })),
    ]),
  ]),

  transition('slideLeftRightAnimation => doubleSlideLeftRightAnimation', [
    style({ position: 'relative' }),
    query(':enter', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        transform: 'translateX(100%)',
      }),
      animate('300ms ease-out', style({ transform: 'translateX(0)' })),
    ]),
  ]),
  transition('doubleSlideLeftRightAnimation => slideLeftRightAnimation', [
    style({ position: 'relative' }),
    query(':leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        transform: 'translateX(0)',
        zIndex: 2000,
      }),
      animate('300ms ease-out', style({ transform: 'translateX(100%)' })),
    ]),
  ]),
  transition('* => slideLeftRightAnimation', [
    style({ position: 'relative' }),
    query(':enter', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        transform: 'translateX(100%)',
      }),
      animate('300ms ease-out', style({ transform: 'translateX(0)' })),
    ]),
  ]),
  transition('slideLeftRightAnimation => *', [
    style({ position: 'relative' }),
    query(':leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        transform: 'translateX(0)',
        zIndex: 2000,
      }),
      animate('300ms ease-out', style({ transform: 'translateX(100%)' })),
    ]),
  ]),
  transition('* => fadeIn', [
    style({ opacity: 0 }),
    animate('100ms ease-out', style({ opacity: 1 })),
  ]),
]);
