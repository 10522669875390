<ng-template #bottomSheetTemplate>
  <div>
    <div class="d-flex align-items-center gap-4 mb-5">
      <div class="p-2" stlye="background-color: red;">
        <img
          style="width: 5rem"
          src="./assets/icons/icon-72x72.png"
          alt="Icon de l'application Go Moto"
        />
      </div>
      <h2>Installer Go Moto</h2>
    </div>
    <p class="mb-4">Installez l'application en deux clics avec Safari.</p>
    <ol class="d-grid gap-3">
      <li>
        <div class="d-flex gap-2 align-items-center">
          <p>Appuyer sur</p>
          <img
            class="p-1 rounded"
            src="./assets/pwa/ios-share.svg"
            alt="Icon de partage d'ios"
            style="background-color: #cdd1e0"
          />
        </div>
      </li>
      <li>
        Puis "<span class="p-2 rounded" style="background-color: #cdd1e0"
          >Sur l'écran d'accueil</span
        >"
      </li>
    </ol>
  </div>
</ng-template>

@if (showInstallButton) {
<div class="install-app">
  <div class="container-install">
    <img
      src="./assets/icons/icon-72x72.png"
      alt="Logo de l'application Go Moto"
    />
    <p class="bold">Go Moto</p>
    <h2>INSTALLE L'app sur ton tel !</h2>
    <p class="speech bold">
      Installe l'application Go Moto directement sur ton téléphone et garde là
      au chaud à porté de main 😉 Une balade est si vite arrivée...
    </p>
    <app-button-component
      [isEnable]="true"
      buttonStyle="black"
      (click)="installPWA()"
      >GOOOOOOOOO!</app-button-component
    >
    <app-button-component [isEnable]="true" (click)="cancelInstall()"
      >PLUS TARD...</app-button-component
    >
  </div>
</div>
}

<div [@routeAnimations]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</div>

@if (message && status) {
<app-toast [message]="message" [status]="status"></app-toast>
}
