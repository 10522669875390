import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthTokenGuard } from './_guards/auth-token.guard';
import { LOCALE_ID } from '@angular/core';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./Pages/loader/loader.component').then((m) => m.LoaderComponent),
  },
  {
    path: 'discussion',
    loadComponent: () =>
      import(
        './Pages/discussion-notifications/discussion/discussion.component'
      ).then((m) => m.DiscussionComponent),
  },
  {
    path: 'user-account/parameters/notifications',
    loadComponent: () =>
      import(
        './Pages/account/parameters/account-parameters-notification/account-parameters-notification.component'
      ).then((m) => m.AccountParametersNotificationComponent),
    data: { animation: 'doubleSlideLeftRightAnimation' },
  },
  {
    path: 'discussion-conditions/:discussionId',
    loadComponent: () =>
      import(
        './Pages/public-group-conditions/public-group-conditions.component'
      ).then((m) => m.PublicGroupConditionsComponent),
  },
  {
    path: 'discussion-message/:id',
    loadComponent: () =>
      import(
        './Pages/discussion-notifications/discussion/discussion-message/discussion-message.component'
      ).then((m) => m.DiscussionMessageComponent),
    data: { animation: 'slideLeftRightAnimation' },
  },
  {
    path: 'not-usable-on-pc',
    loadComponent: () =>
      import(
        './Pages/app-not-usable-on-pc/app-not-usable-on-pc.component'
      ).then((m) => m.AppNotUsableOnPcComponent),
  },
  {
    path: 'discussion-settings/:id',
    loadComponent: () =>
      import(
        './Pages/discussion-notifications/discussion/discussion-message/discussion-settings/discussion-settings.component'
      ).then((m) => m.DiscussionSettingsComponent),
    data: { animation: 'doubleSlideLeftRightAnimation' },
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./Pages/authentication/login/login.component').then(
        (m) => m.LoginComponent
      ),
  },
  {
    path: 'register',
    loadComponent: () =>
      import('./Pages/authentication/register/register.component').then(
        (m) => m.RegisterComponent
      ),
  },
  {
    path: 'loading/:redirectionLink',
    loadComponent: () =>
      import('./Pages/loader/loader.component').then((m) => m.LoaderComponent),
  },
  // {
  //   path: 'welcome',
  //   loadComponent: () =>
  //     import('./Pages/welcome/welcome.component').then(
  //       (m) => m.WelcomeComponent
  //     ),
  // },
  {
    path: 'home',
    loadComponent: () =>
      import('./Pages/home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'user-account/parameters/information',
    loadComponent: () =>
      import('./Pages/information/information.component').then(
        (m) => m.AccountInformationComponent
      ),
    data: { animation: 'doubleSlideLeftRightAnimation' },
  },
  {
    path: 'event/:id',
    loadComponent: () =>
      import('./Pages/event/event.component').then((m) => m.EventComponent),
    data: { animation: 'slideUpDownAnimation' },
  },
  {
    path: 'auth/social-network/:token',
    loadComponent: () =>
      import(
        './Pages/authentication/social-token-user/social-token-user.component'
      ).then((m) => m.SocialTokenUserComponent),
  },
  {
    path: 'auth/login-email',
    loadComponent: () =>
      import('./Pages/authentication/login-email/login-email.component').then(
        (m) => m.LoginEmailComponent
      ),
    data: { animation: 'slideLeftRightAnimation' },
  },
  {
    path: 'auth/register',
    loadComponent: () =>
      import('./Pages/authentication/register/register.component').then(
        (m) => m.RegisterComponent
      ),
  },
  {
    path: 'auth/login-email/forgot-password',
    loadComponent: () =>
      import(
        './Pages/authentication/forgot-password/forgot-password.component'
      ).then((m) => m.ForgotPasswordComponent),
    data: { animation: 'doubleSlideLeftRightAnimation' },
  },
  {
    path: 'auth/reset-password/:token',
    loadComponent: () =>
      import(
        './Pages/authentication/reset-password/reset-password.component'
      ).then((m) => m.ResetPasswordComponent),
  },
  {
    path: 'bookings',
    loadComponent: () =>
      import('./Pages/bookings/bookings.component').then(
        (m) => m.BookingComponent
      ),
  },
  {
    path: 'bookings/booking-information/:ticketId',
    loadComponent: () =>
      import(
        './Pages/bookings/booking-information/booking-information.component'
      ).then((m) => m.BookingInformationComponent),
    data: { animation: 'fadeIn' },
  },
  {
    path: 'commande/event/:eventId',
    loadComponent: () =>
      import('./Pages/commande/commande.component').then(
        (m) => m.CommandeComponent
      ),
    data: { animation: 'slideLeftRightAnimation' },
  },
  {
    path: 'commande/order-confirmation/:ticketId/:ticketQuantity',
    loadComponent: () =>
      import(
        './Pages/commande/order-confirmation/order-confirmation.component'
      ).then((m) => m.OrderConfirmationComponent),
  },
  {
    path: 'verify-email/:token',
    loadComponent: () =>
      import('./Pages/authentication/verify-email/verify-email.component').then(
        (m) => m.VerifyEmailComponent
      ),
  },
  {
    path: 'user-account/parameters/confidentiality',
    loadComponent: () =>
      import('./Pages/confidentiality/confidentiality.component').then(
        (m) => m.ConfidentialityComponent
      ),
    data: { animation: 'doubleSlideLeftRightAnimation' },
  },
  {
    path: 'account',
    loadComponent: () =>
      import('./Pages/account/account.component').then(
        (m) => m.AccountComponent
      ),
  },
  {
    path: 'account/:userId',
    loadComponent: () =>
      import('./Pages/account/account.component').then(
        (m) => m.AccountComponent
      ),
  },
  {
    path: 'user-account/parameters',
    loadComponent: () =>
      import('./Pages/account/parameters/parameters.component').then(
        (m) => m.ParametersComponent
      ),
    data: { animation: 'slideLeftRightAnimation' },
  },
  {
    path: 'user-account/parameters/feedback',
    loadComponent: () =>
      import('./Pages/feedback/feedback.component').then(
        (m) => m.FeedbackComponent
      ),
    data: { animation: 'doubleSlideLeftRightAnimation' },
  },
  {
    path: 'user-account/parameters/terms-of-use',
    loadComponent: () =>
      import(
        './Pages/account/parameters/terms-of-use/terms-of-use.component'
      ).then((m) => m.TermsOfUseComponent),
    data: { animation: 'doubleSlideLeftRightAnimation' },
  },
  {
    path: 'notifications',
    loadComponent: () =>
      import('./Pages/notifications/notifications.component').then(
        (m) => m.NotificationsComponent
      ),
    data: { animation: 'slideLeftRightAnimation' },
    canActivate: [AuthTokenGuard],
  },
  {
    path: 'likes',
    loadComponent: () =>
      import('./Pages/likes/likes.component').then((m) => m.LikesComponent),
    data: { animation: 'slideLeftRightAnimation' },
  },
  {
    path: 'search',
    loadComponent: () =>
      import('./Pages/search/search.component').then((m) => m.SearchComponent),
  },
  {
    path: 'search-map',
    loadComponent: () =>
      import('./Pages/search/search-map/search-map.component').then(
        (m) => m.SearchMapComponent
      ),
    data: { animation: 'noAnimation' },
  },
  {
    path: 'organizer/home-organizer',
    loadComponent: () =>
      import('./Pages/organizer/home-organizer/home-organizer.component').then(
        (m) => m.HomeOrganizerComponent
      ),
  },
  {
    path: 'organizer/scan-qr-code',
    loadComponent: () =>
      import('./Pages/organizer/scan-qr-code/scan-qr-code.component').then(
        (m) => m.ScanQrCodeComponent
      ),
  },
  {
    path: 'organizer/scan-qr-code/scanner',
    loadComponent: () =>
      import('./Pages/organizer/scan-qr-code/scanner/scanner.component').then(
        (m) => m.ScannerComponent
      ),
  },
  {
    path: 'organizer/scan-qr-code/search',
    loadComponent: () =>
      import(
        './Pages/organizer/scan-qr-code/scan-search/scan-search.component'
      ).then((m) => m.ScanSearchComponent),
  },
  {
    path: 'organizer/create-event',
    loadComponent: () =>
      import('./Pages/organizer/create-event/create-event.component').then(
        (m) => m.CreateEventComponent
      ),
  },
  {
    path: 'organizer/edit-event/:eventId',
    loadComponent: () =>
      import('./Pages/organizer/create-event/create-event.component').then(
        (m) => m.CreateEventComponent
      ),
  },
  {
    path: 'adminland',
    loadComponent: () =>
      import('./Pages/admin/admin.component').then((m) => m.AdminComponent),
  },
  {
    path: '404',
    loadComponent: () =>
      import('./Pages/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: LOCALE_ID, useValue: 'fr' }],
})
export class AppRoutingModule {}
